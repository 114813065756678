import { live_event } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      liveEventId: null,
      // Table fields
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
        },
        {
          key: "mobile_number",
        },
        {
          key: "email",
        },
        {
          key: "link_id",
          label: "Link ID",
        },
        {
          key: "status",
        },
      ],
      params: "",
      filter: null,
      filterOn: [],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      key: 0,
      currentPage: 1,
      activeTab: "all",
      showUploadModal: false,
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
        }
      } else if (this.filter.length == 0) this.fetchData("search");
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData("search");
      } else {
        this.fetchData("search");
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = this.pageNo;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // Get protected events members
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = live_event.isProtectedMembers + "/" + this.$route.params.id;
        if (pagination == "search") {
          this.activeTab = pagination;
          url =
            live_event.isProtectedMembers +
            "/" +
            this.$route.params.id +
            "?search=" +
            this.filter;
        } else if (pagination == "all") {
          this.activeTab = pagination;
          url = url + "?filter=" + pagination;
        }
        url += this.params;
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    // Update member status
    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url =
          live_event.isProtectedMembersStatus + "/" + this.$route.params.id;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].status,
          protected_member_id: id,
        });
        if (data.status) {
          this.tableData.data[index].status =
            !this.tableData.data[index].status;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    // For modal
    uploadModal() {
      this.showUploadModal = true;
    },
    closeModal() {
      this.submitted = false;
    },
    handleFileChange(event) {
      // Store the excel file
      this.selectedFile = event.target.files[0];
    },
    async handleSubmit() {
      if (!this.selectedFile) {
        return;
      }
      // Pass the selected file to your upload function
      await this.uploadExcel(this.selectedFile, this.$route.params.id);
      this.selectedFile = null;
    },
    // Upload members excel
    async uploadExcel(file, link_id) {
      this.$store.commit("loader/updateStatus", true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("link_id", link_id);
      try {
        const url = live_event.isProtectedMembersExcel;
        const data = await this.postRequest(url, formData);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.fetchData();
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) {
          this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  mounted() {
    this.fetchData();
  },
};