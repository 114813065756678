<template>
   <Layout>
      <PageHeader :title="title" :items="items"></PageHeader>
      <div class="row" id="liveEvent">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="row align-items-center mb-4">
                     <div class="col-md-6">
                        <div class="form-inline navbar-search" v-if="activeTab == 'all'">
                           <div class="input-group">
                              <input name="title" class="form-control bg-light border-0 small" placeholder="Search..."
                                 v-on:keyup="search" aria-label="Search" aria-describedby="basic-addon2"
                                 v-model="filter" />
                              <div class="input-group-append">
                                 <button class="btn btn-search" @click="searchFor">
                                    <i class="fas fa-search fa-sm"> </i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="all-tabs">
                           <div class="mx-2 opt">
                              <a href="/sample_live_event.xlsx" download class="btn btn-primary btn-sm">
                                 <i class="fa fa-download mr-1"></i> Download Sample File
                              </a>
                           </div>
                           <div class="mx-2 opt">
                              <button class="btn btn-success btn-sm" @click="uploadModal()">
                                 <i class="fa fa-download mr-1"></i> Upload Excel File
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="table-responsive mb-0">
                     <b-table striped bordered :items="tableData.data" :fields="fields" :sort-by.sync="sortBy"
                        :per-page="0" :current-page="currentPage" :sort-desc.sync="sortDesc"
                        :filter-included-fields="filterOn" @filtered="onFiltered" responsive="sm"
                        class="table-bordered table-hover" :key="key">
                        <template v-slot:cell(status)="row">
                           <b-form-checkbox switch class="mb-1" @change="updateStatus(row.item.id)"
                              :checked="row.item.status == 1 ? true : false">
                           </b-form-checkbox>
                        </template>
                     </b-table>
                  </div>
                  <template v-if="tableData.total_pages > 1">
                     <div class="data_paginate">
                        <div class="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                           <ul class="pagination pagination-rounded mb-0">
                              <b-pagination v-model="currentPage" :total-rows="tableData.total"
                                 :total-pages="tableData.total_pages" :per-page="tableData.per_page" first-number
                                 last-number></b-pagination>
                           </ul>
                        </div>
                        <div class="form-inline navbar-search">
                           <span class="mr-2">Go to a Particular Paginated Page :</span>
                           <div class="input-group">
                              <b-form-input id="case" v-model="pageNo" placeholder="Enter Page No" class="in-width">
                              </b-form-input>
                              <div class="input-group-append">
                                 <button class="btn btn-search" @click="filterPage">
                                    <i class="fas fa-search fa-sm"> </i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </template>
               </div>
            </div>
            <!-- Modal -->
            <b-modal id="bvModal" ref="modal" title="Upload Excel File (.csv & .xlsx)" @ok="handleSubmit"
               @cancel="closeModal" v-model="showUploadModal" no-close-on-backdrop centered hide-header-close
               ok-title="Submit" cancel-title="Close">
               <div class="row justify-content-center">
                  <div class="col-lg-12">
                     <b-card header-class="bg-transparent border-primary"
                        class="border border-primary d-flex justify-content-center">
                        <b-form-group class="mt-2" id="input-group-1" label-for="input-1">
                           <div class="">
                              <p class="text-secondary">* Please download smaple file and fill data accordingly.</p>
                              <p class="text-secondary">* Don't put blank cells in excel.</p>
                           </div>
                           <b-form-file
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              id="input-1" placeholder="Choose a file or drop it here..." ref="file"
                              @change="handleFileChange"></b-form-file>
                        </b-form-group>
                     </b-card>
                  </div>
               </div>
            </b-modal>
         </div>
      </div>
   </Layout>
</template>

<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import ProtectedLiveEvent from "../../../mixins/ModuleJs/protected-live-event-members";

export default {
   mixins: [MixinRequest, ProtectedLiveEvent],
   data() {
      return {
         title: "Protected Live Event Members",
         items: [
            {
               text: "Home",
               href: "/",
            },
            {
               text: "List",
            },
         ],
      }
   },
   components: {
      Layout,
      PageHeader
   }
}

</script>